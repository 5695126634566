import { IControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import {
  APP_TOAST_EVENT,
  AppToastTypes,
  Group,
  GroupSortFieldMapping,
} from '@wix/social-groups-api';
import { GroupListState } from '@wix/social-groups-api/dist/src/model/GroupList/GroupListCore';
import { BaseGroupsController } from '../../../common/controllers/groups-list/BaseGroupsController';
import { settingsParams } from '../../../common/settings/groups-list/settings';
import { UpdateProgress } from '../../../common/ContentEditor/UpdateProgress';
import { checkRTL } from '../../../common/utils/checkRTL';
import { GroupsPageProps } from '../types/groupsPageProps';
import { GroupsPageData } from '../Widget/App/types';

class GroupsController extends BaseGroupsController<GroupsPageProps> {
  private sortByFromSettings: any;

  protected async getGroups(config: IControllerConfig) {
    // TODO: refactor for server-side query with sort
    await this.groupListModel.fetch();
    await this.groupListModel.sort((await this.getSortBy()) as any);
    return this.groupListModel.getGroups();
  }

  private async getSortBy() {
    const groupsSortBy = this.groupListModel.getGroupsSortBy();
    if (groupsSortBy) {
      return groupsSortBy;
    }
    const settings = await this.getGroupsInstanceSettings();
    const sortBy = !settings
      ? ApiTypes.v1.GroupsSortBy.RECENT_ACTIVITY
      : settings.groupsSortBy;
    return sortBy;
  }

  async updateConfig($w: any, updatedConfig: IControllerConfig): Promise<void> {
    await this.setApiSortingFromConfig(updatedConfig);
    return super.updateConfig($w, updatedConfig).catch((e) => {
      this.errorLogger.log(e);
      console.log('Error updating config');
    });
  }

  private async setApiSortingFromConfig(updatedConfig: IControllerConfig) {
    try {
      const settingsValuesNew = this.getSortByFromSettings();

      if (this.sortByFromSettings !== settingsValuesNew) {
        this.sortByFromSettings = settingsValuesNew;
        // TODO: api call is sent on every settings change, how to make one on site saved event?
        const apiSort = GroupSortFieldMapping[settingsValuesNew];
        await this.updateGroupInstanceSettings({
          groupsSortBy: apiSort,
        });
        await this.groupListModel.sort(apiSort); // TODO:💩
        this.setState({ groupsSortBy: apiSort });
      }
    } catch (e) {
      this.errorLogger.log(e);
      console.log('Error Updating Group App Setting');
    }
  }

  protected getActions() {
    const actions = super.getActions();
    return {
      ...actions,
      searchGroups: this.searchGroups,
      setPendingGroupApprovalStatus: this.setPendingGroupApprovalStatus,
      withdrawGroupCreationRequest: this.withdrawGroupCreationRequest,
      sortGroups: this.sortGroups,
    };
  }
  async getInitialProps(): Promise<Partial<GroupsPageData>> {
    // Props for SSR
    try {
      const groups = await this.getGroups(this.config);
      this.cacheGroups(groups);
      const groupUrls = await this.getGroupUrls(groups);
      const { createGroupPolicy } = await this.getGroupsInstanceSettings();
      const groupListState: GroupListState = this.groupListModel.getState();
      // TODO: why do we need state in controller?
      this.state = {
        ...groupListState,
        createGroupPolicy,
        isRTL: checkRTL(this.getSiteLanguage()),
        updateProgress: null,
        groupUrls,
      } as any;
      return this.state;
    } catch (e) {
      this.errorLogger.log(e);
      console.log('Groups Controller Initial Props: FAIL');
    }
    this.sortByFromSettings = this.getSortByFromSettings();
    return {};
  }

  private getSortByFromSettings() {
    return this.controllerContext.flowAPI.settings.get(settingsParams.sortBy);
  }

  public withdrawGroupCreationRequest = async (
    group: ApiTypes.v1.GroupResponse,
  ) => {
    const groupModel = new Group(
      group.groupId as any,
      this.api,
      this.platformAPIs,
    );
    await groupModel.setApprovalStatus(
      ApiTypes.v1.GroupApprovalStatus.CANCELLED,
    );
    const newGroups = this.state.groups?.filter(
      (item: ApiTypes.v1.GroupResponse) => item.groupId !== group.groupId,
    );

    this.platformAPIs.pubSub.publish(
      APP_TOAST_EVENT,
      {
        type: AppToastTypes.GROUP_CANCELLED,
        options: {
          'group-name': group.details?.title,
        },
      },
      false,
    );
    this.setState({
      groups: newGroups,
    });
  };

  public setPendingGroupApprovalStatus = async (
    group: ApiTypes.v1.GroupResponse,
    status: ApiTypes.v1.GroupApprovalStatus,
    rejectionReason?: string,
  ) => {
    const groupModel = new Group(
      group.groupId as any,
      this.api,
      this.platformAPIs,
    );
    const groupResponse = await groupModel.setApprovalStatus(
      status,
      rejectionReason,
    );

    let newGroups: ApiTypes.v1.GroupResponse[] = [];
    let toastType;

    switch (status) {
      case ApiTypes.v1.GroupApprovalStatus.APPROVED:
        newGroups =
          this.state.groups?.map((item: ApiTypes.v1.GroupResponse) =>
            item.groupId === groupResponse.groupId ? groupResponse : item,
          ) || [];
        toastType = AppToastTypes.GROUP_APPROVED;

        break;
      case ApiTypes.v1.GroupApprovalStatus.REJECTED:
        newGroups =
          this.state.groups?.filter(
            (item: ApiTypes.v1.GroupResponse) => item.groupId !== group.groupId,
          ) || [];
        toastType = AppToastTypes.GROUP_DECLINED;
        break;
      default:
      // not gonna happen ™
    }

    this.platformAPIs.pubSub.publish(
      APP_TOAST_EVENT,
      {
        type: toastType,
        options: {
          'group-name': group.details?.title,
        },
      },
      false,
    );
    this.setState({
      groups: newGroups,
    });
  };

  public searchGroups = async (groupNameQuery: string) => {
    await this.groupListModel.search(groupNameQuery);
    this.populateGroupsList();
  };

  sortGroups = async (
    sortBy: ApiTypes.v1.GroupsSortBy = ApiTypes.v1.GroupsSortBy.RECENT_ACTIVITY,
  ) => {
    await this.groupListModel.sort(sortBy);
    this.populateGroupsList();
  };

  private populateGroupsList() {
    const groupListState = this.groupListModel.getState();
    this.setState({ ...groupListState, updateProgress: UpdateProgress.STALE });
  }
}

export default GroupsController;
